const base = [
  {
    path: '/reports/receiving-reports/',
    name: 'MalKabulRaporlari',
    component: () => import(/* webpackChunkName: "MalKabulRaporlari" */ '@/views/Reports/GoodsAccept/ReceivingReports'),
    meta: {
      layout: 'AppLayoutDefault',
      title: 'MalKabulUrunRaporu',
      auth: 'ReportManagement.GoodsAcceptReports'
    }
  },
  {
    path: '/reports/receiving-history-reports/',
    name: 'MalKabulGecmisRaporu',
    component: () => import(/* webpackChunkName: "MalKabulGecmisRaporu" */ '@/views/Reports/GoodsAccept/ReceivingHistoryReports'),
    meta: {
      layout: 'AppLayoutDefault',
      title: 'MalKabulGecmisRaporu',
      auth: 'ReportManagement.GoodsAcceptReports'
    }
  },
  {
    path: '/reports/not-on-shelf-orders',
    name: 'RaftaOlmayanUrunler',
    component: () => import(/* webpackChunkName: "RaftaOlmayanUrunler" */ '@/views/Reports/Products/NotOnShelfOrders'),
    meta: {
      layout: 'AppLayoutDefault',
      auth: 'ReportManagement.NonShelfProducts',
      title: 'RaftaOlmayanUrunler'
    }
  },
  {
    path: '/reports/order-transfer-reports',
    name: 'MagazaSiparisGonderimPerformansiRaporu',
    component: () => import(/* webpackChunkName: "ToplanacakUrunler" */ '@/views/Reports/Products/OrderTransferReport'),
    meta: {
      layout: 'AppLayoutDefault',
      auth: 'ReportManagement.ToBePickedProducts',
      title: 'MagazaSiparisGonderimPerformansiRaporu'
    }
  },
  {
    path: '/reports/will-picked-orders',
    name: 'ToplanacakUrunler',
    component: () => import(/* webpackChunkName: "ToplanacakUrunler" */ '@/views/Reports/Products/WillPickedOrders'),
    meta: {
      layout: 'AppLayoutDefault',
      auth: 'ReportManagement.ToBePickedProducts',
      title: 'ToplanacakUrunler'
    }
  },
  {
    path: '/reports/picked-products',
    name: 'ToplananUrunler',
    component: () => import(/* webpackChunkName: "ToplananUrunler" */ '@/views/Reports/Products/PickedProducts'),
    meta: {
      layout: 'AppLayoutDefault',
      auth: 'ReportManagement.PickedProducts',
      title: 'ToplananUrunler'
    }
  },
  {
    path: '/reports/consignment-products',
    name: 'KonsinyeUrunler',
    component: () => import(/* webpackChunkName: "KonsinyeUrunler" */ '@/views/Reports/Products/ConsignmentProducts'),
    meta: {
      layout: 'AppLayoutDefault',
      auth: 'ReportManagement.PickedProducts',
      title: 'KonsinyeUrunler'
    }
  },
  {
    path: '/reports/customer-services',
    name: 'MusteriHizmetlerineYonlendirilmisSiparisler',
    component: () => import(/* webpackChunkName: "KonsinyeUrunler" */ '@/views/Reports/Products/CustomerServices'),
    meta: {
      layout: 'AppLayoutDefault',
      auth: 'ReportManagement.PickedProducts',
      title: 'MusteriHizmetlerineYonlendirilmisSiparisler'
    }
  },
  {
    path: '/reports/missing-products',
    name: 'EksikUrunler',
    component: () => import(/* webpackChunkName: "KonsinyeUrunler" */ '@/views/Reports/Products/MissingProducts'),
    meta: {
      layout: 'AppLayoutDefault',
      auth: 'ReportManagement.PickedProducts',
      title: 'EksikUrunler'
    }
  },
  {
    path: '/reports/order-return',
    name: 'Siparişİade',
    component: () => import(/* webpackChunkName: "KonsinyeUrunler" */ '@/views/Reports/Products/OrderReturn'),
    meta: {
      layout: 'AppLayoutDefault',
      auth: 'ReportManagement.PickedProducts',
      title: 'Sipariş İade Raporu'
    }
  },
  {
    path: '/reports/cargo-control',
    name: 'KargoKontrol',
    component: () => import(/* webpackChunkName: "KonsinyeUrunler" */ '@/views/Reports/Products/CargoControl'),
    meta: {
      layout: 'AppLayoutDefault',
      auth: 'ReportManagement.PickedProducts',
      title: 'Kargo Kontrol'
    }
  },
  {
    path: '/reports/waiting-products',
    name: 'BekleyenUrunlerRaporu',
    component: () => import(/* webpackChunkName: "BekleyenUrunlerRaporu" */ '@/views/Reports/Products/WaitingProducts'),
    meta: {
      layout: 'AppLayoutDefault',
      auth: 'ReportManagement.WaitingProducts',
      title: 'BekleyenUrunlerRaporu'
    }
  },
  {
    path: '/reports/refund-products',
    name: 'IadeAlinanUrunler',
    component: () => import(/* webpackChunkName: "IadeAlinanUrunler" */ '@/views/Reports/Products/RefundProducts'),
    meta: {
      layout: 'AppLayoutDefault',
      auth: 'ReportManagement.RefundOrdersProducts',
      title: 'IadeAlinanUrunler'
    }
  },
  {
    path: "/reports/product-stock-report/:id",
    name: "UrunBazlıStokRaporlari",
    component: () =>
      import(
        /* webpackChunkName: "IadeAlinanUrunler" */ "@/views/Reports/Products/ProductStockReport"
      ),
    meta: {
      layout: "AppLayoutDefault",
      auth: "ReportManagement.StockReports.ShowPage",
      title: "UrunBazlıStokRaporlari",
    },
  },
  {
    path: "/reports/all-product-stock-report",
    name: "TumSayimDosyalariUrünRapolarlari",
    component: () =>
      import(
        /* webpackChunkName: "IadeAlinanUrunler" */ "@/views/Reports/Products/AllProductStockReport"
      ),
    meta: {
      layout: "AppLayoutDefault",
      auth: "ReportManagement.StockReports.ShowPage",
      title: "TumSayimDosyalariUrünRapolarlari",
    },
  },
  {
    path: "/reports/stock-movements-report",
    name: "StokHareketRaporlari",
    component: () =>
      import(
        /* webpackChunkName: "StokHareketRaporlari" */ "@/views/Reports/Products/StockMovementsReport"
      ),
    meta: {
      layout: 'AppLayoutDefault',
      auth: 'ReportManagement.StockReports.ShowPage',
      title: 'UrunBazlıStokRaporlari'
    }
  },
  {
    path: '/reports/stock-movements-report',
    name: 'StokHareketRaporlari',
    component: () => import(/* webpackChunkName: "StokHareketRaporlari" */ '@/views/Reports/Products/StockMovementsReport'),
    meta: {
      layout: 'AppLayoutDefault',
      auth: 'ReportManagement.StockReports.ShowPage',
      title: 'StokHareketRaporlari'
    }
  },
  {
    path: '/reports/warehouse-stock-report',
    name: 'DepoStokRaporu',
    component: () => import(/* webpackChunkName: "DepoStokRaporu" */ '@/views/Reports/Products/WarehouseStockReport'),
    meta: {
      layout: 'AppLayoutDefault',
      auth: 'ReportManagement.StockReports.ShowPage',
      title: 'DepoStokRaporu'
    }
  },
  {
    path: '/reports/wrong-stock-report',
    name: 'HataliStokRaporlari',
    component: () => import(/* webpackChunkName: "HataliStokRaporlari" */ '@/views/Reports/Products/WrongStockReports'),
    meta: {
      layout: 'AppLayoutDefault',
      auth: 'ReportManagement.StockReports.ShowPage',
      title: 'HataliStokRaporlari'
    }
  },
  {
    path: '/reports/stock-report',
    name: 'StokRaporlari',
    component: () => import(/* webpackChunkName: "StokRaporlari" */ '@/views/Reports/Products/StockReports'),
    meta: {
      layout: 'AppLayoutDefault',
      auth: 'ReportManagement.StockReports.ShowPage',
      title: 'StokRaporlari'
    }
  },
  {
    path: '/reports/shelf-stock-report',
    name: 'RafStokRaporlari',
    component: () => import(/* webpackChunkName: "StokRaporlari" */ '@/views/Reports/Products/ShelfStockReports'),
    meta: {
      layout: 'AppLayoutDefault',
      auth: 'ReportManagement.StockReports.ShowPage',
      title: 'StokRaporlari'
    }
  },
  {
    path: '/reports/stock-history-report',
    name: 'GecmisStokRaporlari',
    component: () => import(/* webpackChunkName: "GecmisStokRaporlari" */ '@/views/Reports/Products/StockHistoryReport'),
    meta: {
      layout: 'AppLayoutDefault',
      auth: 'ReportManagement.StockReports.ShowPage',
      title: 'GecmisStokRaporlari'
    }
  },
  {
    path: '/reports/added-products',
    name: 'UrunYerlestirmeRaporlari',
    component: () => import(/* webpackChunkName: "UrunYerlestirmeRaporlari" */ '@/views/Reports/Products/AddedProducts'),
    meta: {
      layout: 'AppLayoutDefault',
      auth: 'ReportManagement.RemovedProducts',
      title: 'UrunYerlestirmeRaporlari'
    },
  },
  {
    path: '/reports/removed-products',
    name: 'UrunCikarmaRaporlari',
    component: () => import(/* webpackChunkName: "UrunCikarmaRaporlari" */ '@/views/Reports/Products/RemovedProducts'),
    meta: {
      layout: 'AppLayoutDefault',
      auth: 'ReportManagement.RemovedProducts',
      title: 'UrunCikarmaRaporlari'
    }
  },
  {
    path: '/reports/car-using-report',
    name: 'ArabaKullanimRaporlari',
    component: () => import(/* webpackChunkName: "ArabaKullanimRaporlari" */ '@/views/Reports/CarUsingReports'),
    meta: {
      layout: 'AppLayoutDefault',
      auth: 'ReportManagement.CarUsingReports',
      title: 'ArabaKullanimRaporlari'
    }
  },
  //SİPARİŞ RAPORLARI
  {
    path: '/reports/bill-printed-orders',
    name: 'FaturasiKesilenSiparisler',
    component: () => import(/* webpackChunkName: "FaturasiKesilenSiparisler" */ '@/views/Reports/Orders/InvoicePrintedOrders'),
    meta: {
      layout: 'AppLayoutDefault',
      auth: 'ReportManagement.QualityControlCompleatedOrders',
      title: 'FaturasiKesilenSiparisler'
    }
  },
  {
    path: '/reports/not-bill-printed-orders',
    name: 'FaturasiKesilemeyenSiparisler',
    component: () => import(/* webpackChunkName: "FaturasiKesilenSiparisler" */ '@/views/Reports/Orders/NotBillPrintedOrders'),
    meta: {
      layout: 'AppLayoutDefault',
      auth: 'ReportManagement.QualityControlCompleatedOrders',
      title: 'FaturasiKesilemeyenSiparisler'
    }
  },
  {
    path: '/reports/shipped-orders',
    name: 'KargoyaVerilenSiparisler',
    component: () => import(/* webpackChunkName: "KargoyaVerilenSiparisler" */ '@/views/Reports/Orders/OrdersAtCargo'),
    meta: {
      layout: 'AppLayoutDefault',
      auth: 'ReportManagement.ShippedOrders',
      title: 'KargoyaVerilenSiparisler'
    }
  },
  {
    path: '/reports/performance-reports',
    name: 'PerformansRaporlari',
    component: () => import(/* webpackChunkName: "PerformansRaporlari" */ '@/views/Reports/PerformanceReports.vue'),
    meta: {
      layout: 'AppLayoutDefault',
      auth: 'ReportManagement.PerformanceReports',
      title: 'PerformansRaporlari'
    }
  }
]

export default base
